.search-sticky {
  position: fixed !important;
  top: 0;
  width: 100%;
  max-width: 500px;
  z-index: 3;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
  padding: 16px !important;
}

.search-sticky + #menu-items-wrapper {
  padding-top: 72px;
}

.intlTelInput {
  .country-list {
    text-align: right;
    .flag-box{
      margin-right:0px;
    }
  }
  input[type=tel]{
    padding-left: 6px !important;
    padding-right: 52px !important;
    text-align: right;
  }
  .flag-container .selected-flag .arrow{
    margin-right: 5px;
  }

 
  .intl-tel-input .country-list .flag-box {
    margin-left: 6px; display: inline-block;
  }
 .country-name {
    margin-left: 6px !important;
    display: inline-block;
  }

 .dial-code {
    color: #999;
    display: inline-block;
    vertical-align: middle;
}
}
.pac-item-arabic {
  .pac-item{
    text-align: right !important;
    direction: rtl;
    .pac-icon{
      margin-right: 0px;
      margin-left:7px;
    }
  }
}
